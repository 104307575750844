import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header
                  as={"h1"}
                  className={"about-us-header"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_header",
                    defaultValue: "ABOUT US",
                  })}
                />
                <Content.Markup
                  width={16}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_description",
                  })}
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
  }
`;
